<template>
  <bg-page>
    <div class="Box">
      <div>
        <FirstTitle :myTitle="state.FirstTitle"></FirstTitle>
      </div>
      <div class="limitBox">
        <div class="SearchBox">
          <div>
            <span class="iconfont iconsousuo"></span>
            <input
              placeholder="请输入关键词搜索"
              type="search"
              v-model="state.title"
            />
          </div>
          <div @click="search">
            <span>搜索</span>
          </div>
        </div>
        <div class="FilterType">
          <FilterType
            :data="state.FilterSearch"
            @GetTypeIndex="GetTypeIndex"
          ></FilterType>
        </div>
        <div class="ContentList">
          <div
            v-for="(item, index) in state.personList"
            :key="index"
            class="personItem"
          >
            <div>
              <div>
                <el-image
                  :src="item.img"
                  fit="cover"
                  style="width: 150px; height: 150px"
                >
                  <template #error>
                    <div class="image-slot">
                      <span>加载中</span>
                    </div>
                  </template>
                </el-image>
              </div>
              <div class="contInfo">
                <p @click="PersonDetails(item)">{{ item.title }}</p>
                <p>产业文化方向：<span v-if="item.cate">{{ item.cate.name }}</span></p>
                <p>{{ item.info }}</p>
              </div>
            </div>
          </div>
        </div>
        <!--分页-->
        <Pagination
          :config="state.PageConfig"
          @handleCurrentPage="handleCurrentPage"
        ></Pagination>
      </div>
    </div>
  </bg-page>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import FirstTitle from "@/components/FirstTitle/FirstTitle";
import Pagination from "@/components/Pagination/Pagination";
import FilterType from "@/components/FilterType/FilterType";
import api from "@/comment/request/api";
export default {
  name: "Character",
  components: { FirstTitle, Pagination, FilterType },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      FirstTitle: {
        ChinaName: "人物信息",
        EnglishName: "Character Information",
      },
      FilterSearch: [
        {
          typeName: "提供类型",
          typeList: [
            {
              value: null,
              label: "全部",
            },
            {
              value: 0,
              label: "专家",
            },
            {
              value: 1,
              label: "领军人物",
            },
            {
              value: 2,
              label: "艺术家",
            },
          ],
        },
        {
          typeName: "所在地区",
          typeList: [],
        },
        {
          typeName: "产业方向",
          typeList: [],
        },
      ],
      personList: [],
      PageConfig: {
        currentPage: 1,
        total: 1,
        pageNum: 8,
      },
      title: "",
      type: null,
    });
    // 详情
    const PersonDetails = (item) => {
      router.push({
        name: "CharacterDetails",
        query: { id: item.character_id },
      });
    };
    // 分页
    const handleCurrentPage = (index) => {
      state.PageConfig.currentPage = index;
      getDataList();
    };
    // 搜索
    const search = () => {
      getDataList();
    };
    // 类别
    api.GetCharacterCate().success((res) => {
      state.FilterSearch[2].typeList = [{value: null, label: '全部'}].concat(res.data.data);
    })
    // 地区
    api.GetRegionList().success((res) => {
      state.FilterSearch[1].typeList = [{value: null, label: '全部'}].concat(res.data.data);
    });
    const GetTypeIndex = (index, item) => {
      if (index == 0) state.type = item.value;
      if (index == 1) state.code = item.value;
      if (index == 2) state.cate_id = item.value;
      state.title = "";
      getDataList();
    };
    // 列表
    const getDataList = () => {
      const params = {
        page: state.PageConfig.currentPage,
        limit: state.PageConfig.pageNum,
        cate_id: state.cate_id,
        title: state.title,
        code: state.code,
        type: state.type,
      };
      api.GetCharacterList(params).success((res) => {
        state.personList = res.data.data.data;
        if (res.data.data) {
          state.PageConfig.total = Number(res.data.data.total);
        }
      });
    };
    getDataList();

    return {
      state,
      handleCurrentPage,
      GetTypeIndex,
      PersonDetails,
      search,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.Box {
  .SearchBox {
    margin-bottom: 10px;
  }

  .ContentList {
    border-top: 5px solid #7f0404;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .personItem {
      padding-top: 40px;
      width: calc(100% / 4);
      z-index: 222;
      > div {
        width: calc(100% - 20px);
        margin: 0 auto;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #7f0404;
        /deep/.el-image {
          border-radius: 50%;
        }
        p:nth-child(1) {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-top: 10px;
          margin-bottom: 5px;
          cursor: pointer;
          &:hover {
            color: #7f0404;
          }
        }
        p:nth-child(2) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        p:nth-child(3) {
          height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          text-align: left;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>